import { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import { RouteUrl } from '../enum';
import Progress from '../components/Progress';
import Login from '../components/Login';
import { CONST_LOCALSTORAGE } from '../constants/localStorage';
import NavbarTop from '../components/NavbarTop';
import LeftSidebar from '../components/LeftSidebar';
import Footer from '../components/Footer';
import { isActiveProfile } from '../utils';
import { Environment, getCurrentApiEnv } from '../config/Environment';
import { CheckSellerValidated } from '../components/CheckSellerValidated';
import { CarriersService } from '../service/shared/carriers';
import { MaintenanceService } from '../service/shared/maintenance';

const Dashboard = lazy(() => import('../pages/dashboard'));
const SimulateDelivery = lazy(() => import('../pages/simulateDelivery'));
const OrderPayment = lazy(() => import('../pages/orderPayment'));
const PixPayment = lazy(
  () => import('../pages/orderPayment/Components/PixPayment'),
);
const OrderCart = lazy(() => import('../pages/orders/orderCart'));
const OrderDetails = lazy(() => import('../pages/requests/orderDetails'));
const OrderRequest = lazy(() => import('../pages/orderRequest'));

const Profile = lazy(() => import('../pages/profile'));
const Integrations = lazy(() => import('../pages/integrations'));
const TutorialNuvemShop = lazy(
  () => import('../pages/integrations/tutorial/nuvemshop'),
);
const PreRegistration = lazy(() => import('../pages/preRegistration'));
const PreOrders = lazy(() => import('../pages/preOrders/preOrder'));
const ShippingPoint = lazy(() => import('../pages/shippingPoint'));
const DefaultPackage = lazy(() => import('../pages/profile/defaultPackage'));
const PackagesPreOrder = lazy(
  () => import('../pages/preOrders/packagesPreOrder'),
);
const EffectivePreOrder = lazy(
  () => import('../pages/preOrders/effectivePreOrder'),
);
const ShippingQuotesPreOrder = lazy(
  () => import('../pages/preOrders/shippingQuotes'),
);
const SimulateOrdersBatch = lazy(() => import('../pages/simulateOrdersBatch'));
const ShippingQuotesBatch = lazy(
  () => import('../pages/simulateOrdersBatch/shippingQuotesBatch'),
);
const LabelPage = lazy(() => import('../components/LabelPage'));
const ShopperRegistration = lazy(
  () => import('../pages/profile/shopperRegistration'),
);
const ShopperDetails = lazy(
  () =>
    import('../pages/profile/shopperRegistration/components/formRegistration'),
);

const RegisterIntegration = lazy(() => import('../pages/registerItengration'));

const WalletPage = lazy(() => import('../pages/wallet'));
const ExtractPage = lazy(() => import('../pages/extract'));
const ConciliationPage = lazy(() => import('../pages/conciliation'));

const MaintenancePage = lazy(() => import('../pages/maintenance'));

const FAQPage = lazy(() => import('../pages/faq'));

const routerPublic = (): boolean => {
  const routersPublic = [
    RouteUrl.ClientRegistration,
    RouteUrl.LabelHTML,
    RouteUrl.MaintenancePage,
  ];
  const isIntegration = window.location.pathname.includes(
    '/register-integration/',
  );

  if (routersPublic.includes(window.location.pathname) || isIntegration)
    return true;
  return false;
};

export function PrivateRoutes() {
  const navigate = useNavigate();
  const maintenanceService = useMemo(() => new MaintenanceService(), []);

  const { isAuthenticated, isLoading, getAccessTokenSilently, logout } =
    useAuth0();
  const [leftSidebarActive, setLeftSidebarActive] = useState(true);
  const [prevPathname, setPrevPathname] = useState<string>('');

  const [loading, setLoading] = useState(true);
  const { resources } = Environment.baseUrl[getCurrentApiEnv()];

  function ProtectedComponent({
    component,
    ...propsForComponent
  }: {
    component: any;
  }) {
    const profile = localStorage.getItem(CONST_LOCALSTORAGE.USER);

    // validar Usuario Ativo
    if (
      profile &&
      !isActiveProfile(JSON.parse(profile)) &&
      window.location.pathname !== '/' &&
      window.location.pathname !== RouteUrl.Profile
    ) {
      navigate('/', {
        state: {
          errorProfile: true,
        },
      });
    }

    const Cp = withAuthenticationRequired(component);
    return (
      <div className="wrapper">
        <CheckSellerValidated />
        <NavbarTop
          setLeftSidebar={() => setLeftSidebarActive(!leftSidebarActive)}
        />
        <LeftSidebar
          active={leftSidebarActive}
          setLeftSidebar={() => setLeftSidebarActive(!leftSidebarActive)}
        />
        <div className={`content-page ${!leftSidebarActive && 'menu-active'}`}>
          <div className="page-container">
            <Suspense>
              <Cp {...propsForComponent} />
            </Suspense>
          </div>
          <Footer />
        </div>
      </div>
    );
  }

  useEffect(() => {
    maintenanceService.getMaintenanceConfig().then(resp => {
      if (
        resp.data.EM_MANUTENCAO &&
        window.location.pathname !== '/maintenance-page'
      ) {
        window.location.href = '/maintenance-page';
      }
    });
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then(resp => {
          localStorage.setItem(CONST_LOCALSTORAGE.TOKEN, resp);
        })
        .finally(() => setLoading(false));
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  const RoutesElement = (() => {
    return (
      <Routes>
        {/* ROTAS PRIVADAS */}
        <Route
          key="dashboard"
          path="/"
          element={<ProtectedComponent component={Dashboard} />}
        />
        <Route
          key="simulateDelivery"
          path={RouteUrl.SimulateDelivery}
          element={<ProtectedComponent component={SimulateDelivery} />}
        />
        <Route
          key="ordersPayment"
          path={RouteUrl.OrdersPayment}
          element={<ProtectedComponent component={OrderPayment} />}
        />
        <Route
          key="ordersPixPayment"
          path={RouteUrl.OrdersPixPayment}
          element={<ProtectedComponent component={PixPayment} />}
        />
        <Route
          key="ordersCart"
          path={RouteUrl.OrdersCart}
          element={<ProtectedComponent component={OrderCart} />}
        />
        <Route
          key="orderRequest"
          path={RouteUrl.OrdersRequest}
          element={<ProtectedComponent component={OrderRequest} />}
        />
        {/* <Route
          key="requests"
          path="/requests"
          element={<ProtectedComponent component={Requests} />}
        /> */}
        <Route
          key="ordersDetails"
          path={RouteUrl.OrdersDetails}
          element={<ProtectedComponent component={OrderDetails} />}
        />
        <Route
          key="profile"
          path={RouteUrl.Profile}
          element={<ProtectedComponent component={Profile} />}
        />

        {resources.integration && (
          <Route
            key="integration"
            path={`${RouteUrl.Integration}/nuvemshop`}
            element={<ProtectedComponent component={TutorialNuvemShop} />}
          />
        )}

        {resources.integration && (
          <Route
            key="integration-nuvemshop"
            path={RouteUrl.Integration}
            element={<ProtectedComponent component={Integrations} />}
          />
        )}

        <Route
          key="effectivePreOrder"
          path={RouteUrl.effectivePreRegister}
          element={<ProtectedComponent component={EffectivePreOrder} />}
        />
        <Route
          key="preOrders"
          path={RouteUrl.PreOrders}
          element={<ProtectedComponent component={PreOrders} />}
        />
        <Route
          key="shippingQuotesPreOrder"
          path={RouteUrl.ShippingQuotesPreOrder}
          element={<ProtectedComponent component={ShippingQuotesPreOrder} />}
        />
        <Route
          key="packagePreOrder"
          path={RouteUrl.PackagePreOrder}
          element={<ProtectedComponent component={PackagesPreOrder} />}
        />
        <Route
          key="shippingPoint"
          path={RouteUrl.ShippingPoint}
          element={<ProtectedComponent component={ShippingPoint} />}
        />
        <Route
          key="defaultPackage"
          path={RouteUrl.DefaultPackage}
          element={<ProtectedComponent component={DefaultPackage} />}
        />

        {resources.simulateBatch && (
          <Route
            key="simulateOrdersBatch"
            path={RouteUrl.SimulateBatch}
            element={<ProtectedComponent component={SimulateOrdersBatch} />}
          />
        )}

        <Route
          key="SimulateBatchShippingQuotes"
          path={RouteUrl.SimulateBatchShippingQuotes}
          element={<ProtectedComponent component={ShippingQuotesBatch} />}
        />
        <Route
          key="CustumerRegistration"
          path={RouteUrl.ShopperRegistration}
          element={<ProtectedComponent component={ShopperRegistration} />}
        />
        <Route
          key="CustumerDetails"
          path={RouteUrl.ShopperDetails}
          element={<ProtectedComponent component={ShopperDetails} />}
        />
        <Route
          key="CustumerDetails"
          path={`${RouteUrl.ShopperDetails}/:id`}
          element={<ProtectedComponent component={ShopperDetails} />}
        />
        {/* ROTAS PUBLICAS */}
        <Route
          key="labelPage"
          path={RouteUrl.LabelHTML}
          element={<LabelPage />}
        />
        <Route
          key="teste"
          path={RouteUrl.ClientRegistration}
          element={<PreRegistration />}
        />
        <Route
          key="registerIntegration"
          path={RouteUrl.RegisterIntegration}
          element={<RegisterIntegration />}
        />
        <Route
          key="wallet"
          path={RouteUrl.Wallet}
          element={<ProtectedComponent component={WalletPage} />}
        />
        <Route
          key="extract"
          path={RouteUrl.Extract}
          element={<ProtectedComponent component={ExtractPage} />}
        />
        <Route
          key="faq"
          path={RouteUrl.FAQ}
          element={<ProtectedComponent component={FAQPage} />}
        />
        <Route
          key="conciliation"
          path={RouteUrl.Conciliation}
          element={<ProtectedComponent component={ConciliationPage} />}
        />
        <Route
          key="maintenance"
          path={RouteUrl.MaintenancePage}
          element={<MaintenancePage />}
        />
      </Routes>
    );
  })();

  const PrivateElement = (() => {
    const isLabelPage = window.location.pathname === RouteUrl.LabelHTML;

    if (isLoading && !isLabelPage) return <Progress />;

    if (!isAuthenticated && !routerPublic()) {
      const urlError = window.location.href.toString();
      const error = urlError.includes('error=access_denied');
      console.log(error);
      if (error) logout();

      return (
        <>
          <Progress />
          <Login />
        </>
      );
    }

    if ((!loading && isAuthenticated) || routerPublic()) {
      return RoutesElement;
    }

    return <Progress />;
  })();

  return PrivateElement;
}
