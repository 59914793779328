import HttpClientService from '../../config/HttpClient';
import { EnumVerificationCode } from '../../enum';
import {
  DefaultDimensions,
  GetClientResponse,
  ShopperData,
  ShopperFilter,
} from './clients.types';

export class ClientsService {
  api: HttpClientService;

  constructor() {
    this.api = new HttpClientService();
  }

  getClient = (id: string): Promise<GetClientResponse> => {
    return this.api.get(`v1/clients/${id}`);
  };

  getClientsValidateUser = () => {
    return this.api.get(`v1/clients/get-by-token`);
  };

  getClientsActives = () => {
    return this.api.get(`v1/clients/actives`);
  };

  getIntegrations = (id: string) => {
    return this.api.get(`v1/clients/${id}/store-integrations`);
  };

  pathDisableIntegration = (id: string, plataform: string) => {
    return this.api.patch(
      `v1/clients/${id}/store-integrations/${plataform}/disable`,
    );
  };

  postGenerateCode = (id: string, type: EnumVerificationCode) => {
    return this.api.post(`v1/clients/new_token`, {
      client_id: id,
      type,
    });
  };

  postSendCode = (token: string, type: EnumVerificationCode) => {
    return this.api.post(`v1/clients/validate_token`, {
      token,
      type,
    });
  };

  postClient = (payload: any) => {
    return this.api.post(`v1/clients`, payload);
  };

  putClient = (payload: any, id: string) => {
    return this.api.put(`v1/clients/${id}`, payload);
  };

  putPasswordClient = (payload: any, id: string) => {
    return this.api.put(`v1/clients/${id}/password`, payload);
  };

  postRecommendation = (payload: any) => {
    return this.api.post(`v1/recommendation`, payload);
  };

  postIntegrationNuvemshop = (id: any) => {
    return this.api.post(`v1/clients/${id}/store-integrations/nuvemshop`, id);
  };

  patchFavouriteBusiness = (id: string, payload: any) => {
    return this.api.patch(`v1/clients/${id}/favourite_business_unit`, payload);
  };

  postDefaultDimensions = (id: string, payload: DefaultDimensions) => {
    return this.api.post(`v1/clients/${id}/default_dimensions`, payload);
  };

  putDefaultDimensions = (id: string, payload: DefaultDimensions) => {
    return this.api.put(`v1/clients/${id}/default_dimensions`, payload);
  };

  deleteDefaultDimensions = (id: string) => {
    return this.api.delete(`v1/clients/${id}/default_dimensions`);
  };

  ceateShopper = (payload: ShopperData) => {
    return this.api.post(`v1/shoppers`, payload);
  };

  getShoppers = (
    payload: ShopperFilter,
    page_number: number,
    page_size: number,
  ) => {
    return this.api.post(
      `v1/shoppers/search?page_number=${page_number}&page_size=${page_size}`,
      payload,
    );
  };

  getShopper = (id: string) => {
    return this.api.get(`v1/shoppers/${id}`);
  };

  updateShopper = (id: string, payload: ShopperData) => {
    return this.api.put(`v1/shoppers/${id}`, payload);
  };

  deleteShopper = (id: string) => {
    return this.api.delete(`v1/shoppers/${id}`);
  };
}
