import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Col, Overlay, Row, Tooltip } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { CartParameters, selectCart } from '../../redux/cart';
import {
  HiddenMobile,
  HiddenLg,
  Container,
  Hamburger,
  Cart,
  Logo,
  LogoMobile,
  SpanCart,
  Logout,
  ButtonContainer,
  WalletContainer,
  WalletContent,
  CardContent,
} from './styled';
import ImgLogo from '../../assets/images/logo/logo1.png';
import Button from '../Button';
import Icon from '../../assets/icons';
import { theme } from '../../styles/theme';
import { RouteUrl } from '../../enum';
import { ErrorTreatment, formatedMoney } from '../../utils';
import { logoutFunction } from '../../utils/logout';
import { selectWallet, updateLoading, updateWallet } from '../../redux/wallet';
import { WalletService } from '../../service/shared/wallet';
import AlertModal from '../Modals/AlertModal';
import NoWalletModal from '../../pages/wallet/components';

import {
  selectCarrierDefault,
  updateCarrierDefaultSettings,
  updateLoading as updateLoadingCarrierDefault,
} from '../../redux/carrierDefaultSettings';
import { convertedMeasuresToCm } from '../../utils/convert';
import { CarriersService } from '../../service/shared/carriers';

type NavbarTopProps = {
  setLeftSidebar: () => void;
};

function NavbarTop({ setLeftSidebar }: NavbarTopProps): ReactElement {
  const carriersService = new CarriersService();
  const { loadingCarrier, alredy_checked } = useSelector(selectCarrierDefault);

  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth0();
  const cart: CartParameters = useSelector(selectCart);
  const orderMessage = useRef(null);
  const walletInfo = useSelector(selectWallet);
  const dispatch = useDispatch();
  const [alertModal, setAlertModal] = useState({
    show: false,
    title: 'Erro ao carregar carteira.',
    text: '',
    textButton: 'Fechar',
  });

  const [noWalletModal, setNoWalletModal] = useState(false);

  const onLogout = () => {
    logoutFunction();
    logout({ returnTo: window.location.origin });
  };

  const walletService = useMemo(() => new WalletService(), []);

  const handleWalletError = useCallback(
    (err: any) => {
      const message = ErrorTreatment(err);
      dispatch(updateLoading(false));
      if (
        location.pathname === RouteUrl.Wallet ||
        location.pathname === RouteUrl.OrdersCart
      ) {
        if (err?.response?.data?.[0]?.code === '404') {
          setNoWalletModal(true);
        } else {
          setAlertModal({
            show: true,
            title: 'Erro ao carregar carteira.',
            text: message?.[0].message ?? ' ',
            textButton: 'Fechar',
          });
        }
      }
    },
    [location.pathname, dispatch],
  );

  const handleGetWallet = useCallback(
    (controller: AbortController) => {
      // if (walletInfo.loading) return;
      dispatch(updateLoading(true));
      walletService
        .getWallet(controller.signal)
        .then(response => {
          dispatch(
            updateWallet({
              ...response.data,
              loading: false,
            }),
          );
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            console.log('Request canceled', err.message);
          } else {
            handleWalletError(err);
          }
        });
    },
    [handleWalletError, dispatch, walletService],
  );

  useEffect(() => {
    if (!loadingCarrier && !alredy_checked) {
      updateLoadingCarrierDefault(true);
      carriersService
        .getDimensions()
        .then(res => {
          dispatch(
            updateCarrierDefaultSettings({
              limits: convertedMeasuresToCm(res.data),
              alredy_checked: true,
              loadingCarrier: false,
            }),
          );
        })
        .finally(() => dispatch(updateLoadingCarrierDefault(false)));
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    handleGetWallet(controller);

    return () => {
      controller.abort();
    };
  }, [location]);

  return (
    <>
      <NoWalletModal
        show={noWalletModal}
        visibleModal={() => setNoWalletModal(false)}
      />

      <AlertModal
        show={alertModal.show}
        type="danger"
        size="lg"
        values={alertModal}
        visibleModal={() => setAlertModal(prev => ({ ...prev, show: false }))}
      />
      <Container>
        <Row>
          <Col xs={4} sm={4} lg={3}>
            <Logo src={ImgLogo} alt="Logo" />
            <Hamburger>
              <Button variant="transparent" onClick={() => setLeftSidebar()}>
                <Icon name="bars" color={theme.colors.primaryColor} />
              </Button>
            </Hamburger>
          </Col>
          <HiddenLg xs={4} lg={3}>
            <LogoMobile src={ImgLogo} alt="Logo" />
          </HiddenLg>
          <Col xs={4} sm={2} lg={6}>
            <WalletContainer>
              <Cart
                ref={orderMessage}
                onClick={() => navigate(RouteUrl.Wallet)}
                hideMobile
              >
                <Icon name="wallet" color={theme.colors.primaryColor} />
                <WalletContent>
                  <div>
                    Saldo
                    <br />
                    <SpanCart>
                      {formatedMoney.format(walletInfo.balance)}
                    </SpanCart>
                  </div>
                  <div
                    style={{
                      width: '1px',
                      height: '100%',
                      backgroundColor: '#8e9ca8',
                    }}
                  />
                  <div>
                    Etiquetas
                    <br />
                    <SpanCart>
                      {walletInfo.label_limit - walletInfo.labels_used}{' '}
                      disponíveis
                    </SpanCart>
                  </div>
                </WalletContent>
              </Cart>
              <Cart
                ref={orderMessage}
                onClick={() => navigate(RouteUrl.OrdersCart)}
              >
                <Icon name="shopping-cart" color={theme.colors.primaryColor} />
                <CardContent>
                  Carrinho
                  <br />
                  <SpanCart>-</SpanCart>
                </CardContent>
              </Cart>
            </WalletContainer>
            <Overlay
              target={orderMessage.current}
              show={cart.cartOrderMessageAdd}
              placement="bottom"
            >
              <Tooltip id="tooltip-bottom">
                Pedido adicionado ao carrinho.
              </Tooltip>
            </Overlay>
          </Col>
          <HiddenMobile sm={5} lg={3}>
            <ButtonContainer>
              <Button onClick={() => navigate('/simulate-delivery')}>
                Simular Entrega
                <Icon name="arrow-right" />
              </Button>
              <Logout onClick={() => onLogout()}>
                <Icon name="sign-out-alt" color={theme.colors.primaryColor} />
              </Logout>
            </ButtonContainer>
          </HiddenMobile>
        </Row>
      </Container>
    </>
  );
}

export default NavbarTop;
